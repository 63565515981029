<template>
    <nav class="fi-navbar">
        <div class="logo">
            <router-link to="/">
                <img src="@/assets/images/fi-logo.png" alt="Firm Industries">
            </router-link>
        </div>
        <button class="btn toggle-nav" @click.prevent="expanded = !expanded">
            <div :class="{ b1 : expanded }"></div>
            <div :class="{ b2 : expanded }"></div>
            <div :class="{ b3 : expanded }"></div>
        </button>
        <div class="right">
            <div class="contact">
                <p>Contact info</p>
                <p>
                    <span>Baldiya Road , Jazeera Al Hamra, Ras Al Khaimah, UAE</span>
                    <span>P.O Box: 32137</span>
                </p>
                <p>Phone: <a href="tel:+97172447271">+971 7 244 7271</a></p>
                <p>E-mail: <a href="mailto:sales@firm-industries.com">Sales@firm-industries.com</a></p>
            </div>
            <div class="links">
                <router-link to="/">Home</router-link>
                <router-link to="/construction">Construction</router-link>
                <!-- <router-link to="/hotel">Hotel</router-link> -->
                <router-link to="/consumable">Consumable</router-link>
            </div>
        </div>
    </nav>

    <div class="fi-navbar-expand" :class="{ expanded : expanded }">
        <div class="links">
            <a href="#" @click.prevent="$router.push('/'); expanded = false;">Home</a>
            <a href="#" @click.prevent="$router.push('/construction'); expanded = false;">Construction</a>
            <!-- <a href="#" @click.prevent="$router.push('/hotel'); expanded = false;" to="/hotel">Hotel</a> -->
            <a href="#" @click.prevent="$router.push('/consumable'); expanded = false;" to="/consumable">Consumable</a>
        </div>
        <div class="contact">
            <p>Contact info</p>
            <p>Baldiya Road , Jazeera Al Hamra, Ras Al Khaimah, UAE</p>
            <p>P.O Box: 32137</p>
            <p>Phone: <a href="tel:+97172447271">+971 7 244 7271</a></p>
            <p>E-mail: <a href="mailto:sales@firm-industries.com">Sales@firm-industries.com</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data() {
        return {
            expanded: false,
            mobile: false,
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    methods: {
        onResize() {
            if(window.innerWidth <= 991 && !this.mobile) {
                this.mobile = true;
            } else if(this.mobile && window.innerWidth > 991) {
                this.mobile = false;
                this.expanded = false;
            }
        }
    },
    watch: {
        expanded() {
            if(this.expanded) {
                document.body.style.touchAction = 'none';
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
                document.documentElement.style.touchAction = 'none';
                this.$emit('expanded', true);
            }
            else {
                document.body.style.touchAction = 'auto';
                document.body.style.overflow = 'auto';
                document.documentElement.style.overflow = 'auto';
                document.documentElement.style.touchAction = 'auto';
                this.$emit('expanded', false);
            }
        }
    }
}
</script>

<style scoped>
button.toggle-nav {
    display: none;
}

button > div {
    width: 25px;
    height: 3px;
    background-color: #D0B78A;
    margin: 4px 0;
    transition: 0.4s;
}

button > div.b1 {
    -webkit-transform: rotate(-45deg) translate(-4px, 2px);
    transform: rotate(-45deg) translate(-4px, 2px);
}

button > div.b2 {
    opacity: 0;
}

button > div.b3 {
    -webkit-transform: rotate(45deg) translate(-8px, -6px);
    transform: rotate(45deg) translate(-8px, -6px);
}

.fi-navbar-expand {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    width: 280px;
    height: calc(100% - 80px);
    background-color: #4A4B58;
    color: #D0B78A;
    transform: translateX(280px);
    right: 0;
    top: 80px;
    transition: transform 0.4s ease-in-out;
    z-index: 10;
    overflow-y: none;
}

.fi-navbar-expand .links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fi-navbar-expand .links a {
    color: inherit;
    padding: 10px;
}

.fi-navbar-expand .contact {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12.5px;
    color: #fff;
}

.fi-navbar-expand .contact a {
    color: inherit;
}

nav.fi-navbar {
    position: fixed;
    width: 100%;
    background-color: #4A4B58;
    color: #D0B78A;
    height: 110px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #D0B78A;
    z-index: 10;
}

nav.fi-navbar > a {
    color: inherit;
}

.logo img {
    height: 95px;
    margin-right: 25px;
    margin-left: 5px;
}

.right {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

nav.fi-navbar .contact {
    background-color: #F2F2F3;
    height: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #4A4B58;
    font-size: 12px;
    font-family: Montserrat-Light;
}

nav.fi-navbar .contact p, a {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
}

nav.fi-navbar .contact a:hover {
    color: inherit;
}

nav.fi-navbar .contact > p {
    padding: 0 2.5%;
    line-height: 25px;
}

nav.fi-navbar .contact > p > span {
    font-family: inherit;
}
nav.fi-navbar .contact > p > span:first-child {
    margin-right: 15px;
}

nav.fi-navbar .contact > p {
    border-right: 1px solid;
}

nav.fi-navbar .contact > p:last-of-type {
    border: none;
}

nav.fi-navbar .links {
    color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40%;
}

nav.fi-navbar .links a {
    padding: 0 10%;
    border-right: 1px solid;
    line-height: 25px;
    font-family: Montserrat-Light;
}

nav.fi-navbar .links a:last-child {
    border: none;
}

nav.fi-navbar .links a:hover {
    color: inherit;
}

@media only screen and (max-width: 991px) {
    .right {
        display: none;
    }
    nav.fi-navbar {
        height: 80px;
        padding-left: calc(50% - 40px);
        justify-content: space-between;
    }
    .logo img {
        height: 80px;
        margin: 0 !important;
    }
    button.toggle-nav {
        display: block;
    }
    .fi-navbar-expand.expanded {
        transform: translateX(0);
    }
}
</style>