import { createRouter, createWebHistory } from 'vue-router';
import Index from '../layouts/Index.vue';

import Home from '../views/index/Home.vue';

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home | Firm Industries',
      layout: Index,
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    },
  },
  {
    path: '/consumable',
    name: 'Consumable',
    component: () => import('../views/index/Consumable.vue'),
    meta: {
      title: 'Consumable | Firm Industries',
      layout: Index,
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    },
  },
  {
    path: '/construction',
    name: 'Construction',
    component: () => import('@/views/index/Construction.vue'),
    meta: {
      title: 'Construction | Firm Industries',
      layout: Index,
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    },
  },
  {
    path: '/:pathMatch(.*)*',
    // alias: '/:pathMatch(.*)',
    name: '404',
    component: () => import('@/views/index/404.vue'),
    meta: {
      title: '404 | Firm Industries',
      layout: Index,
      metaTags: [
        {
          name: 'description',
          content: 'The page you\'re looking for doesn\'nt exist'
        },
        {
          property: 'og:description',
          content: 'The page you\'re looking for doesn\'nt exist'
        }
      ]
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to) => {
  
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  // if(!nearestWithMeta) return next();
  
  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

});

export default router;