<template>
    <div class="bg">
        <div class="wrap">
            <img src="../../assets/images/home-man.svg" class="man" alt="Firm Industries">

            <div class="about-us">
                <h1 class="title">About Us</h1>
                <p class="subtitle">
                    The company was established in 1987 under the guidance of
                    His Highness Sheikh Tariq Al Qasimi, and since then
                    Firm has been delivering high quality, innovative and
                    affordable products and solutions to wholesalers and
                    retails across the globe.
                </p>
                <p class="subtitle">
                    Our products, either manufactured in our production
                    facilities or sourced out from reliable partners and producers,
                    go through under a thorough process of supervision and quality
                    control.
                </p>
            </div>
        </div>
    </div>

    <div class="about-us-2">
        <h1 class="title">About Us</h1>
        <p class="subtitle">
            The company was established in 1987 under the guidance of
            His Highness Sheikh Tariq Al Qasimi, and since then
            Firm has been delivering high quality, innovative and
            affordable products and solutions to wholesalers and
            retails across the globe.
        </p>
        <p class="subtitle">
            Our products, either manufactured in our production
            facilities or sourced out from reliable partners and producers,
            go through under a thorough process of supervision and quality
            control.
        </p>
    </div>

    <div class="border-middle"></div>
    <div class="p2">
        <img class="bottom-bg-left" src="../../assets/images/hotel-btm-bg.svg" alt="bottom background">
        <img class="bottom-bg-right" src="../../assets/images/hotel-btm-bg.svg" alt="bottom background">
        <div class="brand">
            <div class="frame"></div>
            <div class="inner">
                <div class="text-center">
                    <p>It's not just about <span>Brand</span></p>
                    <p>It's about <span>Quality</span></p>
                </div>
            </div>
        </div>
        <h4 class="title">partners</h4>
        <div class="partners">
            <div class="frame"></div>
            <img src="../../assets/images/home-perfect-property-management.png" alt="Perfect property Management">
            <img src="../../assets/images/home-rak-holdings.png" alt="RAK Holding">
            <img src="../../assets/images/home-uniestate.png" alt="UniEstate">
        </div>
    </div>

    <img class="bottom-bg-left" src="../../assets/images/hotel-btm-bg.svg" alt="bottom background">
    <img class="bottom-bg-right" src="../../assets/images/hotel-btm-bg.svg" alt="bottom background">
</template>

<script>
export default {
    name: 'Home',
    mounted() {
        document.body.style.backgroundColor = '#DCDCDD';
    },
}
</script>

<style scoped>
div.bg {
    background: url('../../assets/images/home-bg-3.png') no-repeat;
    /* background-size: 100% 100vh; */
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: -1;
}


.border-middle {
    height: 21px;
    width: 100%;
    background-color: #D0B78A;
    position: absolute;
    top: 100vh;
}

.p2 {
    top: 100vh;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
}

div.partners {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 40px;
}

div.partners .frame {
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 80px;
    border: 2px solid #D0B78A;
    z-index: -1;
}

div.partners img {
    height: 140px;
    margin-right: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
    border-radius: 2px;
    -user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

div.partners img:last-of-type {
    margin-right: 0;
}

@media only screen and (max-width: 680px) {
    div.partners {
        flex-direction: column;
        margin-bottom: 20px;
    }

    div.partners img {
        margin-right: 0;
        margin-bottom: 20px;
    }

    div.partners .frame {
        top: 0;
        bottom: 0;
        right: 80px;
        left: 80px;
    }
}

img.brand {
    width: 500px;
    margin-bottom: 50px;
    -user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

div.brand {
    position: relative;
    padding: 40px 25px;
    margin-bottom: 15px;
}

div.brand .frame {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 60px;
    border: 2px solid #D0B78A;
    z-index: -1;
}

div.brand .inner {
    padding: 20px 20px;
    background-color: #4A4B58;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DCDCDD;
}

div.brand .inner p {
    color: #DCDCDD;
    font-size: 27px;
    -user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

div.brand .inner p span {
    color: #D0B78A;
    font-family: Montserrat-Bold;
}

.wrap {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

img.man {
    height: calc(100vh - 130px);
}

.about-us {
    margin: 0 0 20px 20px;
    width: 40%;
}

h1.title {
    font-family: Montserrat-Bold;
    color: #4A4B58;
    padding: 0 0px 0;
    font-size: 44px;
}


h4.title {
    font-family: Montserrat-Bold;
    color: #4A4B58;
    padding: 0 35px 0;
    text-transform: uppercase;
    margin-bottom: 30px;
}

p.subtitle {
    font-family: Montserrat-Light;
    color: #4A4B58;
    margin-top: 20px !important;
    padding: 0 0px 0;
    font-size: 18px;
}

.container {
    position: absolute;
    padding-top: calc(100vh + 30px);
    max-width: calc(100vw - 50px);
}

img.m {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 30vw;
}

img.bottom-bg-left {
    opacity: 0.7;
    position: absolute;
    mix-blend-mode: lighten;
    top: 100vh;
    left: 0;
    z-index: -2;
    height: 800px;
}

img.bottom-bg-right {
    opacity: 0.7;
    position: absolute;
    mix-blend-mode: lighten;
    top: 100vh;
    right: 0;
    z-index: -2;
    height: 800px;
}

@media only screen and (max-width: 600px) {
    img.brand {
        width: 400px;
    }
}

@media only screen and (max-width: 450px) {
    div.brand .inner p {
        font-size: 5vw;
    }
}

@media only screen and (max-width: 991px) {
    img.man {
        display: none;
    }

    div.bg {
        background-position: right !important;
    }

    .about-us {
        width: 75% !important;
    }

}

@media only screen and (max-width: 950px) {
    .about-us {
        width: 65% !important;
    }
}

@media only screen and (max-width: 899px) {
    .about-us p {
        font-size: 17px;
    }
}

@media only screen and (max-width: 845px) {
    .about-us {
        width: 55% !important;
    }
    .about-us p {
        font-size: 15px;
    }
}

.about-us-2 {
    display: none;
    padding: 20px;
    background-color: #e2e0e3;
}

.p2 > img.bottom-bg-left, .p2 > img.bottom-bg-right {
    display: none;
}

@media only screen and (max-width: 720px) {
    .wrap {
        display: none;
    }

    div.bg {
        position: relative;
    }

    .about-us-2 {
        display: block;
    }

    .p2 {
        position: relative;
        top: 0;
    }

    .border-middle {
        position: relative;
        top: 0;
    }

    img.bottom-bg-left, img.bottom-bg-right {
        display: none;
    }

    .p2 > img.bottom-bg-left, .p2 > img.bottom-bg-right {
        display: block;
        top: 0;
    }
}
</style>