<template>
  <navbar ref="navbar" @expanded="toggleOverlay" />
  <overlay :expanded="expanded" @off="off" />
  <router-view />
</template>

<script>
import Navbar from '@/components/index/Navbar.vue';
import Overlay from '@/components/index/Overlay.vue';

export default {
  name: 'Index',
  data() {
    return {
      expanded: false
    }
  },
  components: {
    Navbar,
    Overlay
  },
  methods: {
    toggleOverlay(expand) {
      this.expanded = expand;
    },
    off() {
      this.expanded = false;
      this.$refs.navbar.expanded = false;
    }
  }
}
</script>
