<template>
  <component v-bind:is="this.$route.meta.layout">
    <router-view />
  </component>
</template>

<style>
@font-face {
  font-family: Montserrat-Regular;
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat-Light;
  src: url('./assets/fonts/Montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('./assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat-Thin;
  src: url('./assets/fonts/Montserrat/Montserrat-Thin.ttf');
}

* {
  font-family: Montserrat-Regular;
  margin: 0;
  padding: 0;
}

p {
  margin: 0 !important;
}
</style>
