<template>
    <div class="overlay" :class="{ on: expanded }" @click.prevent="$emit('off');"></div>
</template>

<script>
export default {
    name: 'Overlay',
    props: [
        'expanded'
    ]
}
</script>

<style scoped>
div.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms ease 0s;
}

div.on {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
}
</style>